import DomainContextParams from '@/utils/types/DomainContextParams';

export const PRODUCT_GENERIC_PAGE_HEADER_FRAGMENT = `
  fragment productGenericPageHeaderFragment on Product {
    id
    uid
    name
    __typename
    images {
      fullFileResource {
        schemaCode
        path
      }
    }
    categoriesInContext(context: "${DomainContextParams.product}") {
      uid
      name
    }
    url
    featured
    _isRecommendedForMe
    _isExhibitorFeatured
  }
`;
